import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

export default () => (
  <Layout>
    <SEO
      title="About VASCEPA® (icosapent ethyl)"
      description="Learn more about VASCEPA® (icosapent ethyl) and how it works to protect your heart. Please see Indication and Important Safety Information."
      />
    <h1>Page 3.0</h1>
    <ul>
    	<li><Link to="/page-3.1">3.1</Link></li>
    	<li><Link to="/page-3.2">3.2</Link></li>
    	<li><Link to="/page-3.3">3.3</Link></li>
    </ul>


  </Layout>
)
